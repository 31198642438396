import React, { useState, useCallback, RefObject } from "react";
import { IconStyles } from "../icon/Icon.types";
import style from "./FileInput.module.scss";
import labels from "../../data/labels";
import { getClassNames } from "../../helpers/classHelpers";
import { Icons } from '../../ui/icon/icons/material';
import { Icon } from '../../ui/icon/Icon';
import { generateUniqueId } from '@danfoss/mosaic-core';
import { ALLOWED_FILE_EXTENSIONS } from '../../constants/consts';

interface FileDropAreaProps {
    onFilesSelected?: (files: FileList) => void;
    onInvalidFiles?: () => void;
    inputFileRef: RefObject<HTMLInputElement>;
}

const isValidFileExtension = (fileName: string) => {
    const extension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity));
    return ALLOWED_FILE_EXTENSIONS.includes(extension);
};

const FileDropArea: React.FC<FileDropAreaProps> = ({ onFilesSelected, onInvalidFiles, inputFileRef }) => {
    const [isDragging, setIsDragging] = useState(false);
    const uniqueId = generateUniqueId();

    const createFileList = useCallback((files: File[]): FileList => {
        const dataTransfer = new DataTransfer();
        files.forEach(file => dataTransfer.items.add(file));
        return dataTransfer.files;
    }, []);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);

        const files = event.dataTransfer.files;
        if (files && onFilesSelected) {
            const validFiles = Array.from(files).filter(file => isValidFileExtension(file.name));
            if (validFiles.length > 0) {
                onFilesSelected(createFileList(validFiles)); // Pass valid files to the parent
            } else {
                if (onInvalidFiles) {
                    onInvalidFiles();
                }
            }
        }

        // Reset the input value to allow selecting the same file again
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    }, [onFilesSelected, onInvalidFiles, inputFileRef, createFileList]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && onFilesSelected) {
            const validFiles = Array.from(files).filter(file => isValidFileExtension(file.name));
            if (validFiles.length > 0) {
                onFilesSelected(createFileList(validFiles));
            } else {
                if (onInvalidFiles) {
                    onInvalidFiles();
                }
            }
        }

        // Reset the input value to allow selecting the same file again
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    }, [onFilesSelected, onInvalidFiles, inputFileRef, createFileList]);

    return (
        <div
            className={getClassNames([
                style["df-file-drop"],
                isDragging && style["df-file-drop--dragging"],
            ])}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <p className={style["file-drop-content"]}>
                <Icon.Base
                    title={labels.submitTitle}
                    iconName={Icons.upload}
                    iconStyle={IconStyles.filled}
                    className={getClassNames([style["df-icon"]])}
                />
                {labels.dropFilesAreaUploadText}
                <input
                    type="file"
                    ref={inputFileRef}
                    id={uniqueId}
                    className="df-btn-file"
                    tabIndex={-1}
                    onChange={handleFileChange}
                    aria-label={labels.dropFilesAreaBrowseText}
                    accept={ALLOWED_FILE_EXTENSIONS.join(",")}
                />
                <label
                    htmlFor={uniqueId}
                    tabIndex={0}
                    aria-controls={uniqueId}
                >
                    {labels.dropFilesAreaBrowseText}
                </label>
            </p>
        </div>
    );
};

export default FileDropArea;