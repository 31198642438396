export enum Status {
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
    Default = 'default',
}

export enum DocumentUploadStatus {
    Ready = 'ready',
    Error = 'invalid',
    Processing = 'processing'
}

export function getStatusLabelType(status: DocumentUploadStatus): Status {
    switch (status.toLowerCase()) {
        case DocumentUploadStatus.Ready.toLowerCase():
            return Status.Success;
        case DocumentUploadStatus.Processing.toLowerCase():
            return Status.Warning;
        case DocumentUploadStatus.Error.toLowerCase():
            return Status.Danger;
        default:
            return Status.Default;
    }
}

