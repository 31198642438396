import LoadingIcon from '../../loading-icon/LoadingIcon';
import { useCallback } from 'react';
import { getClassNames } from '../../../helpers/classHelpers';
import { IconProps, IconStyles, IconTheme } from '../Icon.types';
import styles from '../Icon.module.scss';

const Icon = ({ testId, isClickable, iconStyle = IconStyles.outlined, iconName, size = 24, isLoading, className, title, isDisabled = false, theme = IconTheme.dark, onClick, dataAttributes }: IconProps) => {
    const handleClick = useCallback(async () => !isDisabled && onClick?.(), [isDisabled, onClick]);
    if (!iconName) return null;
    const clickable = ((onClick || isClickable) && !isDisabled);
    const classNames = getClassNames([iconStyle, className, clickable && styles.clickable, styles['icon'], size && styles[`size-${size}`], theme && styles[`theme-${theme}`]]);
    if (isLoading) return <LoadingIcon className={getClassNames([styles.loader, classNames])} size={size} />;

    return <span
        tabIndex={0}
        role={(onClick && !isDisabled) ? "button" : undefined}
        className={classNames}
        title={title}
        aria-disabled={isDisabled}
        onClick={handleClick}
        data-testid={testId}
        {...dataAttributes}
    >
        {iconName}
    </span>;
};

export default Icon;