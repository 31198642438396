import React from 'react';
import ListItem, { ListItemProps } from '../list-item/ListItem';
import LoadingIcon from '../loading-icon/LoadingIcon';
import styles from './ListItems.module.scss';
import Loader from '../loader/Loader';

type Props = {
    items: ListItemProps[];
    isLoading: boolean;
    isUploading: boolean;
    loadingItemId: string | null;
} & Pick<ListItemProps, 'itemsSelected' | 'onItemSelect' | 'childrenClassName' | 'footerClassName' | 'headerClassName'>;

const ListItems = ({ items, itemsSelected, onItemSelect, isLoading, isUploading, loadingItemId, ...rest }: Props) => {
    return (
        <div className={styles['list-items-container']}>
            {isUploading && <Loader />}

            <ul className={styles['list-items']}>
                {[...items].reverse().map((props, i) => {
                    const isLoadingItem = loadingItemId === props.id;
                    return (
                        <li key={i} className={styles['list-item-wrapper']}>
                            <ListItem
                                itemsSelected={itemsSelected}
                                onItemSelect={onItemSelect}
                                {...rest}
                                {...props}
                            />
                            {isLoadingItem && (
                                <div className={styles['loader-overlay']}>
                                    <LoadingIcon />
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ListItems;