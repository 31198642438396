import React, { useState, useCallback, useRef, useEffect } from "react";
import FileDropArea from "../../../../../file-input/FileInput";
import labels from "../../../../../../data/labels";
import Button from "../../../../../button/Button";
import DocumentList from "./components/document-list/DocumentList";
import Loader from "../../../../../loader/Loader";
import Information from './components/information/Information';
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoute } from "../../../../../../router/Routing";
import { useChat } from "../../../../../../contexts/chat/ChatContext";
import { DocumentInfoModel, DocumentRow, DocumentsResponse, DocumentUpdateRequest } from "../../../../../../models/ChatTypes";
import { TestIds } from "../../../../../../mocks/ids";
import { DocumentUploadStatus } from '../../../../../../helpers/statusHelpers';
import { useErrorHandlerContext } from '../../../../../../contexts/error-handler/ErrorContext';
import { getExtension, isFileSizeValid } from '../../../../../../helpers/fileHelpers';
import { ErrorHandler } from '../../../../../../contexts/error-handler/ErrorHandler';
import styles from "./Document.module.scss";
import { maxAllowedFileSize } from '../../../../../../constants/consts';

const CopyToNewChatButton = ({ onClick, data, chatId, setIsLoading }: { onClick: React.MouseEventHandler<HTMLButtonElement>; data: { rows: DocumentRow[]; }; chatId: string; setIsLoading: (value: React.SetStateAction<boolean>) => void; }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { persistent } = useChat();
    const { setDocumentIds } = persistent;
    const { copyDocuments } = useChat();
    const { errorId, registerError, removeError } = useErrorHandlerContext();

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(e);

        setIsLoading(true);

        try {
            removeError(errorId);
            const result: DocumentsResponse = await copyDocuments(chatId);

            if (result.errors && Object.keys(result.errors).length > 0) {
                const errorMessages = Object.values(result.errors).join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.copyErrorHeadline, description: errorMessages } });
            } else {
                // Extract document IDs from result.documents
                const documentIds = Object.values(result.documents).map((doc) => doc.id);

                // Update document IDs in persistent state
                setDocumentIds(documentIds);

                // Navigate to the chat route if not already there
                if (pathname !== AppRoute.chat) {
                    navigate(AppRoute.chat);
                }
            }
        } catch (error: any) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            registerError({ [errorId]: { type: 'notification', headline: labels.copyErrorHeadline, description: errorMessage, details: error } });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button
            label={labels.copyToNewChatButton}
            onClick={handleClick}
        />
    );
};

export const Documents = ({ chatId }: { chatId: string; }) => {
    const [data, setData] = useState<{ rows: DocumentRow[]; }>({ rows: [] });
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { persistent, uploadDocument, fetchDocuments, updateDocumentMetadata, deleteDocuments, downloadDocuments } = useChat();
    const { setDocumentIds, removeDocumentId, getAllDocumentIds } = persistent;
    const initRef = useRef(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const { errorId, registerError, removeError } = useErrorHandlerContext();

    useEffect(() => { removeError(errorId); }, [errorId, removeError]);

    const loadDocuments = useCallback(async () => {
        removeError(errorId);
        setIsLoading(true);
        try {
            const documentIds = getAllDocumentIds();
            const response = await fetchDocuments(documentIds, chatId);

            const documentRows: DocumentRow[] = [];

            if (response.errors && Object.keys(response.errors).length > 0) {
                const errorMessages = Object.entries(response.errors)
                    .map(([id, errorMessage]) => `${errorMessage}`)
                    .join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: errorMessages } });
            }

            if (response.documents) {
                Object.values(response.documents).forEach((doc) => {
                    documentRows.push({
                        documentId: doc.id,
                        fileName: doc.display.name || '',
                        description: doc.display.description || '',
                        status: doc.state?.status || DocumentUploadStatus.Ready,
                        size: doc.size,
                        extension: doc.format,
                    });
                });
            }

            setData({ rows: documentRows });
        } catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: labels.fetchErrorMessage, details: error } });
        } finally {
            setIsLoading(false);
        }
    }, [removeError, errorId, getAllDocumentIds, fetchDocuments, chatId, registerError]);

    useEffect(() => {
        if (!initRef.current) {
            loadDocuments();
            initRef.current = true;
        }
    }, [loadDocuments]);

    const getFileExtension = useCallback((file: File) => {
        const { extension, name } = getExtension(file.name) || { extension: 'unknown', name: 'undefined' };
        return [name, extension];
    }, []);

    const handleFilesSelected = useCallback(async (files: FileList) => {
        removeError(errorId);

        if (!files.length) return;

        const file = files[0];

        const [fileName, extension] = getFileExtension(file);

        // Check if the file size is valid
        if (!isFileSizeValid(file, maxAllowedFileSize)) {
            registerError({ [errorId]: { type: 'notification', headline: labels.uploadErrorHeadline, description: labels.exceededDocSizeError } });
            return;
        }

        const isDuplicate = data.rows.some(row => row.fileName === fileName);

        if (isDuplicate) {
            const duplicateErrorMessage = labels.duplicateDocumentError.replace("{file.name}", fileName);
            registerError({ [errorId]: { type: 'notification', headline: labels.duplicatNameErrorHeadline, description: duplicateErrorMessage } });
            return;
        }


        const newRow = {
            documentId: '',
            fileName,
            description: "",
            status: DocumentUploadStatus.Processing,
            size: file.size,
            extension,
        };

        // changeUploadStatus(DocumentUploadStatus.Processing);
        setData(prev => ({ rows: [...prev.rows, newRow] }));
        setIsUploading(true);

        try {
            const metadata: DocumentInfoModel = await uploadDocument(file, chatId);


            if (metadata) {
                if (!chatId) {
                    setDocumentIds([metadata.id]);
                }

                const uploadStatus = metadata.state?.status || DocumentUploadStatus.Ready;

                setData(prev => ({
                    rows: prev.rows.map(row => {
                        return row.fileName === fileName ? { ...newRow, documentId: metadata.id, size: metadata.size, extension: metadata.format, status: uploadStatus } : row;
                    }),
                }));
            }
        } catch (error: any) {
            // changeUploadStatus(DocumentUploadStatus.Error);
            registerError({ [errorId]: { type: 'notification', headline: labels.uploadErrorHeadline, description: labels.uploadErrorMessage, details: error } });
            setData(prev => ({
                rows: prev.rows.map(row => row.fileName === fileName ? { ...row, status: DocumentUploadStatus.Error } : row),
            }));
        } finally {
            setIsUploading(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    }, [removeError, errorId, data.rows, getFileExtension, registerError, uploadDocument, chatId, setDocumentIds]);

    const fetchAndUpdateDocuments = useCallback(async () => {
        try {
            await loadDocuments();
        } catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: labels.fetchErrorMessage, details: error } });
        }
    }, [loadDocuments, registerError, errorId]);

    const handleDeleteDocuments = useCallback(async (selectedIds: string[]) => {
        removeError(errorId);
        try {
            const { delete_document_ids, errors } = await deleteDocuments(selectedIds, chatId);

            if (!chatId) {
                delete_document_ids.forEach(removeDocumentId);
            }
            // TODO: Revisit to check the state
            await fetchAndUpdateDocuments();

            if (errors && Object.keys(errors).length) {
                const errorMessages = Object.values(errors).join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.deleteErrorHeadline, description: `${labels.deleteErrorMessage} ${errorMessages}` } });
            }
        } catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.deleteErrorHeadline, description: labels.deleteErrorMessage, details: error } });
        }
    }, [removeError, errorId, deleteDocuments, chatId, fetchAndUpdateDocuments, removeDocumentId, registerError]);

    const handleDownloadDocuments = useCallback(async (selectedIds: string[]) => {
        removeError(errorId);
        try {
            const success = await downloadDocuments(selectedIds);
            if (!success) registerError({ [errorId]: { type: 'notification', headline: labels.downloadErrorHeadline, description: labels.downloadErrorMessage } });
        } catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.downloadErrorHeadline, description: labels.downloadErrorMessage, details: error } });
        }
    }, [removeError, errorId, downloadDocuments, registerError]);

    const handleUpdateDocument = useCallback(async (fileId: string, metadata: DocumentUpdateRequest) => {
        removeError(errorId);
        try {
            await updateDocumentMetadata(fileId, metadata);
            await fetchAndUpdateDocuments();
        } catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.updateErrorHeadline, description: `${labels.updateErrorMessage} ${error instanceof Error ? error.message : String(error)}`, details: error } });
        }
    }, [removeError, errorId, updateDocumentMetadata, fetchAndUpdateDocuments, registerError]);

    const handleInvalidFiles = useCallback(() => {
        registerError({ [errorId]: { type: 'notification', headline: labels.invalidDocumentTypeHeader, description: labels.invalidDocumentTypeMessage } });
    }, [errorId, registerError]);

    return (
        <div className={styles.wrapper} data-testid={TestIds.documentView}>
            <Information />
            <div className={styles.fileDropArea}>
                <FileDropArea onFilesSelected={handleFilesSelected} onInvalidFiles={handleInvalidFiles} inputFileRef={fileInputRef} />
            </div>
            <ErrorHandler.Notification id={errorId} />

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {data.rows.length > 0 && (
                        <DocumentList
                            documents={data.rows}
                            onSubmit={handleUpdateDocument}
                            onDownload={handleDownloadDocuments}
                            onDelete={handleDeleteDocuments}
                            isUploading={isUploading}
                        />
                    )}

                    {chatId && data.rows.length > 0 && (
                        <div className={styles.copyButtonContainer}>
                            <CopyToNewChatButton onClick={() => {}} data={data} chatId={chatId} setIsLoading={setIsLoading} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
