import Chip, { ChipTheme, Version } from '../chip/Chip';
import styles from './ChipList.module.scss';

export const ChipList = ({ chips }: { chips?: { label: string, theme?: ChipTheme; }[]; }) => {
    return (
        <div className={styles['chip-container']}>
            {chips?.map(({ label, theme }, i) => (
                <Chip key={i} label={label} theme={theme} version={Version.minified} />
            ))}
        </div>
    );
};

export default ChipList;