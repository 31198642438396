import DocumentUpdateModal from '../../../../../../../../elements/document-update-modal/DocumentUpdateModal';
import ChipList from '../../../../../../../chip-list/ChipList';
import Description from '../../../../../../../description/Description';
import ListItems from '../../../../../../../list-items/ListItems';
import StatusHeader from '../../../../../../../status-header/StatusHeader';
import DocumentListActions from '../document-list-actions/DocumentListActions';
import useLabels from '../../../../../../../../hooks/useLabels';
import useDocumentEvents from '../../../../../../../../elements/hooks/useDocumentEvents';
import Button, { ButtonThemes } from '../../../../../../../button/Button';
import Chip, { ChipTheme, Version } from '../../../../../../../chip/Chip';
import { useState, useCallback, useMemo, FC, useEffect } from 'react';
import { TestIds } from '../../../../../../../../mocks/ids';
import { DocumentRow, DocumentUpdateRequest } from '../../../../../../../../models/ChatTypes';
import { readableFileSize } from '../../../../../../../../helpers/fileHelpers';
import { DocumentUploadStatus, getStatusLabelType } from '../../../../../../../../helpers/statusHelpers';
import { useErrorHandlerContext } from '../../../../../../../../contexts/error-handler/ErrorContext';
import { DocumentEventTypes } from '../../../../../../../../models/BackendEvents';
import styles from './DocumentList.module.scss';

type Props = {
    documents: DocumentRow[];
    onSubmit: (fileId: string, metadata: DocumentUpdateRequest) => Promise<void>;
    onDownload: (selectedIds: string[]) => void;
    onDelete: (selectedIds: string[]) => void;
    isUploading: boolean;
};

const DocumentList: FC<Props> = ({ documents, onSubmit, onDownload, onDelete, isUploading }) => {
    const labels = useLabels();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<{ name: string; description: string; fileId: string; } | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingItemId, setLoadingItemId] = useState<string | null>(null);

    const { errorId, registerError, removeError } = useErrorHandlerContext();

    useEffect(() => { removeError(errorId); }, [errorId, removeError]);

    const { events } = useDocumentEvents();

    const handleErrorRegistering = useCallback((headline: string, details: any) => registerError({ [errorId]: { type: 'notification', headline, details } }), [errorId, registerError]);

    const handleSelect = useCallback((id: string) => setSelectedIds(s => {
        if (s.indexOf(id) > -1) return s.filter(x => x !== id);
        return [...s, id];
    }), []);

    const handleEdit = useCallback((item: { name: string, description: string, fileId: string; }) => { // TODO: Fix this typing
        setSelectedDocument(item);
        setErrorMessage(null);
        setModalOpen(true);
    }, []);

    const items = useMemo(() => documents.map((document) => {
        const { fileName, description, status, size, extension, documentId } = document || {};

        const eventStatus = events[documentId] === DocumentEventTypes.processed ? DocumentUploadStatus.Ready : events[documentId] === DocumentEventTypes.failed ? DocumentUploadStatus.Error : DocumentUploadStatus.Processing;
        const calculatedStatus = events[documentId] ? eventStatus : status; // TODO: Fix this + above

        const statusLabelMap = () => { // TODO: Move out?
            return {
                [DocumentUploadStatus.Error]: labels.docErrorState,
                [DocumentUploadStatus.Processing]: labels.docProcessingState,
                [DocumentUploadStatus.Ready]: labels.docReadyState
            };
        };

        return {
            id: documentId,
            name: fileName,
            description: description,
            status,
            size,
            extension,
            header: <StatusHeader headline={fileName} />,
            footer: !description ? <Button className={styles['description-cta']} theme={ButtonThemes.textPrimary} label={labels.addDescription} onClick={() => handleEdit({ name: fileName, description, fileId: documentId })} /> : <Description description={description} labels={labels} />,
            children: <div className={styles['chip-wrapper']}>
                {/* // TODO: Fix this typing */}
                <Chip className={styles.progress} label={statusLabelMap()[calculatedStatus]} theme={getStatusLabelType(calculatedStatus) as any as ChipTheme} version={Version.minified} />
                <span className={styles.separator}>|</span>
                <ChipList chips={[
                    { label: readableFileSize(size) },
                    { label: extension }
                ]} />
            </div>,
            onItemSelect: handleSelect
        };
    }), [documents, events, handleEdit, handleSelect, labels]);

    const handleSelectAll = useCallback(() => setSelectedIds(items.map(item => item.id)), [items]);
    const handleDeselectAll = useCallback(() => setSelectedIds([]), []);

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedDocument(null);
        setErrorMessage(null);
    };

    const handleSubmit = async (fileId: string, name: string, description: string) => {
        const metadata: DocumentUpdateRequest = { display: { name, description } };
        setLoadingItemId(fileId);
        setIsLoading(true);

        try {
            await onSubmit(fileId, metadata);
            handleCloseModal();
        }
        catch (error) {
            handleErrorRegistering(labels.updateMetadataErrorMessage, error);
        }
        finally {
            setIsLoading(false);
            setLoadingItemId(null);
        }
    };

    const handleDownload = async (selectedIds: string[]) => {
        setIsLoading(true);
        setLoadingItemId(selectedIds[0]);
        try {
            await onDownload(selectedIds);
        }
        catch (error) {
            handleErrorRegistering(labels.downloadErrorMessage, error);
        }
        finally {
            setIsLoading(false);
            setLoadingItemId(null);
        }
    };

    const handleDelete = async (selectedIds: string[]) => {
        setLoadingItemId(selectedIds[0]);
        setIsLoading(true);
        try {
            await onDelete(selectedIds);
            setSelectedIds([]);
        }
        catch (error) {
            handleErrorRegistering(labels.deleteErrorMessage, error);
        }
        finally {
            setIsLoading(false);
            setLoadingItemId(null);
        }
    };

    return (
        <div data-testid={TestIds.documentList} className={styles['document-list']}>
            <DocumentListActions
                items={items}
                selectedIds={selectedIds}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                onEdit={handleEdit}
                onDownload={() => handleDownload(selectedIds)}
                onDelete={() => handleDelete(selectedIds)}
            />

            <ListItems
                childrenClassName={styles.children}
                footerClassName={styles.footer}
                headerClassName={styles.header}
                itemsSelected={selectedIds}
                items={items}
                isLoading={isLoading}
                isUploading={isUploading}
                loadingItemId={loadingItemId}
            />

            {selectedDocument && (
                <DocumentUpdateModal
                    errorId={errorId}
                    registerError={handleErrorRegistering}
                    removeError={removeError}
                    initialName={selectedDocument.name}
                    initialDescription={selectedDocument.description}
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSubmit={(name, description) => handleSubmit(selectedDocument.fileId, name, description)}
                    errorMessage={errorMessage}
                />
            )}
        </div>
    );
};

export default DocumentList;