import React, { useState, type PropsWithChildren } from 'react';
import Button, { ButtonThemes } from '../button/Button';
import { getClassNames } from '../../helpers/classHelpers';
import { DfAlert } from '@danfoss/mosaic-react';
import { type AlertType } from '@danfoss/mosaic-core';
import style from './Alert.module.scss';

export type AlertProps = {
    headline?: string;
    description?: string;
    type?: AlertType; //TODO: Use status enum

    isWithCloseButton?: boolean;
    isVisible?: boolean;

    onClose?: () => void;

    className?: string;

    detailsClassName?: string;
    details?: JSX.Element;
    collapseDetails?: boolean;
    /**The label of the button to display the content in case collapseContent is set to true.
     * Default: 'See more details'
     */
    displayDetailsCta?: string;
};

const Alert: React.FC<PropsWithChildren<AlertProps>> = ({ headline, description, type, isWithCloseButton, isVisible = true, onClose, children, className, collapseDetails, displayDetailsCta = 'See more details', details, detailsClassName }) => {

    const [displayContent, setDisplayContent] = useState(!collapseDetails);

    return <>
        <DfAlert
            className={className}
            type={type}
            isVisible={isVisible}
            heading={headline}
            description={description}
            isWithCloseButton={!!onClose || isWithCloseButton}
            onAlertClose={onClose}
        >
            {children}
        </DfAlert>

        {details && <div className={getClassNames([style.details, detailsClassName])}>
            {displayContent ? details : <Button className={style.button} label={displayDetailsCta} theme={ButtonThemes.textSecondary} onClick={() => setDisplayContent(true)} />}
        </div>}
    </>;
};

export default Alert;