import useTableSorting from './hooks/useTableSorting/useTableSorting';
import useFormStyling from './hooks/useFormStyling/useFormStyling';
import useDynamicTable from './hooks/useDynamicTable/useDynamicTable';
import useTableSearch from './hooks/useTableSearch/useTableSearch';
import useImageExpanding from './hooks/useImageExpanding/useImageExpanding';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getClassNames } from '../../helpers/classHelpers';
import { initializeMarkdownIt } from './helpers';
import { ErrorLabels, MarkdownItWithIncrementalDOM } from '../../models/types';
import 'highlight.js/styles/a11y-dark.min.css'; // Syntax highlighting theme
import './markdown.scss'; // Markdown styles - code blocks, general line-height etc.
import style from './MarkdownWrapper.module.scss';

const unclosedMdUrl = /\[(.*?)\]\((?!.*?\))[^)]*$/gsuim;

type Props<ErrorKey extends string> = {
    isLoading?: boolean;
    markdown: Parameters<MarkdownItWithIncrementalDOM['renderToIncrementalDOM']>[0];
    errorMessageDict?: ErrorLabels<ErrorKey>,
    defaultError?: ErrorKey;
    className?: string;
    imageBaseUrl?: string;
};

const MarkdownWrapper = <ErrorKey extends string = string>({ isLoading, markdown, errorMessageDict, defaultError, className, imageBaseUrl }: Props<ErrorKey>) => {
    const elm = useRef<HTMLDivElement>(null);
    const [initialRenderDone, setInitialRenderDone] = useState(false);

    const md = useMemo(() => initializeMarkdownIt({ errorMessageDict, defaultError, imageBaseUrl }), [errorMessageDict, defaultError, imageBaseUrl]);

    useEffect(() => {
        const target = elm.current;
        if (!target) return;

        let suffix = markdown.match(unclosedMdUrl) ? ")" : "";

        window.IncrementalDOM.patch(target, md.renderToIncrementalDOM(markdown + suffix));
        setInitialRenderDone(true);
    }, [markdown, md]);

    const props = useMemo(() => ({ isActive: initialRenderDone && !isLoading, dependencies: [markdown] }), [initialRenderDone, isLoading, markdown]);

    const { renderModal } = useImageExpanding(props);
    useTableSorting(props);
    useTableSearch(props);
    useFormStyling(props);
    useDynamicTable(props);

    return <div className={getClassNames([className, style['markdown-wrapper'], 'markdown-body'])}>
        <div ref={elm} />
        {renderModal}
    </div>;
};


export default MarkdownWrapper;