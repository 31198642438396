import useLabels from '../../hooks/useLabels';
import ChatWindowHeader from '../chat-window-header/ChatWindowHeader';
import ChatWelcome from '../chat-welcome/ChatWelcome';
import useInfo from '../../hooks/useInfo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute, getChatItemRoute } from '../../router/Routing';
import { defaultPersonaKey, defaultTemperature, promptOriginNewChatOverrides } from '../../constants/consts';
import { ChatProps } from '../../views/chat-view/ChatView';
import { useChat } from '../../contexts/chat/ChatContext';
import { ChatInput, ChatInputModel } from '../chat-input';
import { validateInputModelSelection } from '../../views/chat-view/usePersistentInputState';
import { TestIds } from '../../mocks/ids';
import style from './NewChatWindow.module.scss';

const NEW_PROMPT_PERSISTANCE_KEY = 'new-session';

const NewChatWindow: React.FC<ChatProps> = ({ onOpenSidebar, newPromptOrigin, onReloadChats }) => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { createChat, persistent } = useChat();
    const { llmOptions, allowedPersonaOptions } = useInfo();
    const { persistent: { documentIds, removeDocumentId } } = useChat();

    const promptOrigin = useMemo(() => newPromptOrigin || NEW_PROMPT_PERSISTANCE_KEY, [newPromptOrigin]);

    const defaultLlm = useMemo(() => llmOptions?.[0]?.value, [llmOptions]);
    const { getInput, setInput, mergeInput, setAutoSubmit } = persistent;
    const [inputModel, setInputModel] = useState({
        ...getInput(promptOrigin, { message: '', temperature: defaultTemperature, persona: defaultPersonaKey, llm: defaultLlm }),
        ...(promptOriginNewChatOverrides[promptOrigin] ?? {})
    });

    const { getPersonaFromKey } = useInfo();
    const isDocumentUploadEnabled = useMemo(() => getPersonaFromKey(inputModel.persona!)?.documents?.enabled, [inputModel.persona, getPersonaFromKey]);

    // Close side bar on persona without document upload feature
    useEffect(() => {
        if (isDocumentUploadEnabled) return;
        navigate(AppRoute.chat);
    }, [isDocumentUploadEnabled, navigate]);

    const onModelMerge = useCallback((model: Partial<ChatInputModel>) => {
        setInputModel(m => ({ ...m, ...model }));
        mergeInput(promptOrigin, model);
    }, [mergeInput, promptOrigin]);

    useEffect(() => {
        const { isValidSelection, isValidLlmSelection, isValidPersonaSelection } = validateInputModelSelection({ llm: inputModel.llm, persona: inputModel.persona }, llmOptions, allowedPersonaOptions);
        if (isValidSelection) return;

        if (!isValidLlmSelection) onModelMerge({ llm: defaultLlm });
        if (!isValidPersonaSelection) onModelMerge({ llm: defaultPersonaKey });
    }, [inputModel.llm, inputModel.persona, llmOptions, allowedPersonaOptions, defaultLlm, onModelMerge]);

    const labels = useLabels();
    const l = useMemo(() => {
        return {
            chatTitle: labels.newChatTitle,
            placeholder: labels.inputPlaceholder,
            disclaimerNote: labels.disclaimerNote,
        };
    }, [labels]);

    const onSendPromptHandler = useCallback(async () => {
        const { session_id, documents } = await createChat(
            inputModel.message || '',
            inputModel.llm || '',
            inputModel.temperature ?? defaultTemperature,
            inputModel.persona,
            isDocumentUploadEnabled ? documentIds : undefined
        );

        // Remove the persisted input model of the new session and assign the message to the input model of the newly created session
        mergeInput(promptOrigin, { message: '' });

        setInput(session_id, { message: inputModel.message });
        setAutoSubmit(session_id);

        if (session_id && isDocumentUploadEnabled) {
            documents.forEach(removeDocumentId);
        }

        navigate({ pathname: getChatItemRoute(session_id), hash });
        onReloadChats?.(true);
    }, [createChat, inputModel.message, inputModel.llm, inputModel.temperature, inputModel.persona, isDocumentUploadEnabled, documentIds, mergeInput, promptOrigin, setInput, setAutoSubmit, navigate, hash, onReloadChats, removeDocumentId]);

    return (<>
        <div data-testid={TestIds.chatWindow} className={style['chat-window']}>
            <ChatWindowHeader
                initialTitle={l.chatTitle}
                onBurgerClick={onOpenSidebar}
            />

            <div className={style['chat-messages']}>
                <ChatWelcome />
            </div>

            <div className={style['input-container']}>
                <ChatInput
                    documents={isDocumentUploadEnabled ? documentIds : undefined}
                    placeholder={l.placeholder}
                    inputNote={l.disclaimerNote}
                    model={inputModel}

                    personaOptions={allowedPersonaOptions}
                    llmOptions={llmOptions}
                    onModelMerge={onModelMerge}
                    onSubmitPrompt={onSendPromptHandler}

                    displayNewChatOptions={true}
                />
            </div>
        </div>
    </>);
};

export default NewChatWindow;