import React, { useCallback, useMemo, useState } from 'react';
import Checkbox from '../../../../../../../checkbox/Checkbox';
import { getClassNames } from '../../../../../../../../helpers/classHelpers';
import { Icon } from '../../../../../../../icon/Icon';
import { IconTheme } from '../../../../../../../icon/Icon.types';
import { Icons } from '../../../../../../../icon/icons/material';
import styles from './DocumentListActions.module.scss';
import Prompt from '../../../../../../../prompt/Prompt';
import useLabels from '../../../../../../../../hooks/useLabels';

const DocumentListActions = ({
    items,
    selectedIds,
    onSelectAll,
    onDeselectAll,
    onEdit,
    onDownload,
    onDelete,
}: {
    items: any[],
    selectedIds: string[];
    onSelectAll: () => void;
    onDeselectAll: () => void;
    onEdit: (documentData: { fileId: string, name: string; description: string; }) => void;
    onDownload: (selectedIds: string[]) => void;
    onDelete: (selectedIds: string[]) => void;
}) => {
    const labels = useLabels();
    const [showPrompt, setShowPrompt] = useState(false);
    const [documentsToDelete, setDocumentsToDelete] = useState<string | null>(null);

    const handleDownload = useCallback(() => {
        if (selectedIds.length === 1) {
            onDownload(selectedIds);
        }
    }, [onDownload, selectedIds]);

    const confirmDelete = useCallback(() => {
        onDelete(selectedIds);
        setShowPrompt(false);
    }, [onDelete, selectedIds]);

    const handleDelete = useCallback(() => {
        if (selectedIds.length > 0) {
            const selectedDocuments = items
                .filter(item => selectedIds.includes(item.id))
                .map(item => item.name);

            if (selectedDocuments.length > 0) {
                setDocumentsToDelete(selectedDocuments.join(', '));
                setShowPrompt(true);
            }
        }
    }, [items, selectedIds]);

    const handleEdit = useCallback(() => {
        if (selectedIds.length === 1) {
            const selectedDocument = items.find(item => item.id === selectedIds[0]);
            if (selectedDocument) {
                onEdit({
                    fileId: selectedDocument.id,
                    name: selectedDocument.name,
                    description: selectedDocument.description
                });
            }
        }
    }, [items, selectedIds, onEdit]);

    const isAllSelected = useMemo(() => items.length === selectedIds.length, [items.length, selectedIds.length]);

    const actionsMap = useMemo(() => [
        {
            label: labels.docListDownload,
            iconName: Icons.download,
            isDisabled: selectedIds.length !== 1,
            onClick: handleDownload
        },
        {
            label: labels.docListDelete,
            iconName: Icons.delete,
            isDisabled: selectedIds.length < 1,
            onClick: handleDelete
        },
        {
            label: labels.docListEdit,
            iconName: Icons.edit,
            isDisabled: selectedIds.length > 1 || selectedIds.length === 0,
            onClick: handleEdit
        }
    ], [handleDelete, handleDownload, handleEdit, selectedIds.length, labels]);

    return (
        <div className={styles['actions-container']}>
            <Checkbox isChecked={isAllSelected} onChange={isAllSelected ? onDeselectAll : onSelectAll} />
            <div className={styles.actions}>
                {actionsMap?.map((props, i) => <ListActionLabel key={i} {...props} />)}
            </div>

            <Prompt
                isOpen={showPrompt}
                onClose={() => setShowPrompt(false)}
                headline={labels.deleteDocumentModalTitle}
                message={documentsToDelete
                    ? labels.deleteDocumentConfirmationText.replace("{documentName}", documentsToDelete)
                    : labels.deleteDocumentConfirmationText}
                primaryButton={{ label: labels.deleteDocumentDeleteButton, onClick: confirmDelete }}
                secondaryButton={{ label: labels.deleteDocumentCancelButton }}
                closeOnBackdropClick
                closeOnEscape
            />
        </div>
    );
};

const ListActionLabel = ({ iconName, isDisabled, label, onClick }: { iconName: Icons, isDisabled: boolean, label: string, onClick?: () => void; }) => {
    return (
        <label className={getClassNames([isDisabled && styles['_disabled']])} onClick={isDisabled ? undefined : onClick}>
            <Icon.Base theme={IconTheme.inherit} size={18} iconName={iconName} title={''} isClickable={!isDisabled} />
            {label}
        </label>
    );
};

export default DocumentListActions;