import useLabels from '../../hooks/useLabels';
import ButtonAsync from '../../ui/button/ButtonAsync';
import Input from '../../ui/input/Input';
import Modal from '../../ui/modal/Modal';
import Button, { ButtonThemes } from '../../ui/button/Button';
import Textarea from '../../ui/textarea/Textarea';
import labels from '../../data/labels';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import style from './DocumentUpdateModal.module.scss';

const INVALID_FILE_NAME_CHARS = /[<>:"/\\|?*]/;
const ALLOWED_FILE_NAME_CHARS = labels.allowedFileNameError;

type DocumentUpdateModalProps = {
    errorId: string;
    registerError: (headline: string, error?: any) => void;
    removeError: (errorId: string) => void;
    initialName?: string;
    initialDescription?: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (name: string, description: string) => Promise<void>;
    errorMessage: string | null;
};

const DocumentUpdateModal: React.FC<DocumentUpdateModalProps> = ({
    errorId,
    registerError,
    removeError,
    initialName = '',
    initialDescription = '',
    isOpen,
    onClose,
    onSubmit,
}) => {
    const labels = useLabels();
    const l = useMemo(() => ({
        header: labels.documentUpdateHeader,
        namePlaceholder: labels.namePlaceholder,
        descriptionPlaceholder: labels.descriptionPlaceholder,
        cancel: labels.cancel,
        update: labels.update,
        invalidFileNameError: `${labels.invalidFileNameError} ${ALLOWED_FILE_NAME_CHARS}`,
        uploadErrorHeadline: labels.uploadErrorHeadline,
    }), [labels]);

    const [name, setName] = useState(initialName);
    const [description, setDescription] = useState(initialDescription);

    useEffect(() => {
        setName(initialName);
        setDescription(initialDescription);
    }, [initialName, initialDescription]);

    const isFileNameValid = useMemo(() => {
        if (!name.trim()) return false;
        if (name.length > 255) return false;
        if (INVALID_FILE_NAME_CHARS.test(name)) return false;
        return true;
    }, [name]);

    // Allow the description to be empty for the form to be valid
    const isFormValid = useMemo(() => isFileNameValid, [isFileNameValid]);

    const handleSubmit = useCallback(async () => {
        removeError(errorId);

        if (!isFileNameValid) {
            registerError(l.invalidFileNameError);
            return;
        }

        try {
            await onSubmit(name, description); // Allow empty description
        } catch (err) {
            registerError(l.uploadErrorHeadline);
        }
    }, [removeError, errorId, isFileNameValid, registerError, l.invalidFileNameError, l.uploadErrorHeadline, onSubmit, name, description]);

    return (
        <Modal
            isOpen={isOpen}
            headline={l.header}
            className={style['document-update-modal']}
            body={(
                <>
                    <ErrorHandler.Notification id={errorId} />
                    <div className={style.inputContainer}>
                        <fieldset className={style['df-form-field']}>
                            <p className={style.label}>{l.namePlaceholder}</p>
                            <Input
                                placeholder={l.namePlaceholder}
                                value={name}
                                onChange={setName}
                                className={style.input}
                            />
                            {!isFileNameValid && (
                                <p className={style.error}>
                                    {l.invalidFileNameError}
                                </p>
                            )}
                        </fieldset>
                        <fieldset className={style['df-form-field']}>
                            <p className={style.label}>{l.descriptionPlaceholder}</p>
                            <Textarea
                                placeholder={l.descriptionPlaceholder}
                                value={description}
                                onChange={setDescription}
                                className={style.input}
                            />
                        </fieldset>
                    </div>
                </>
            )}
            closeOnEscape
            closeOnBackdropClick
            footer={(
                <>
                    <Button label={l.cancel} onClick={onClose} theme={ButtonThemes.textPrimary} />
                    <ButtonAsync
                        label={l.update}
                        onClick={handleSubmit}
                        isDisabled={!isFormValid}
                    />
                </>
            )}
            size='medium'
            onClose={onClose}
        />
    );
};

export default DocumentUpdateModal;