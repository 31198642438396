import useLabels from "../../../hooks/useLabels";
import Modal from "../../../ui/modal/Modal";
import Alert, { AlertProps } from "../../../ui/alert/Alert";
import { FC, ReactNode, useMemo } from "react";
import { ModalType, useErrorHandlerContext } from "../ErrorContext";
import { formatDetails } from "../helpers";
import { RequiredIdentifierType } from "../types";
import { getClassNames } from "../../../helpers/classHelpers";
import style from "../ErrorHandler.module.scss";

export type ModalCompoundProps = RequiredIdentifierType & { headline?: string; footer?: ReactNode, alertOverride?: AlertProps; };
export const ModalCompound: FC<ModalCompoundProps> = ({ className, id, headline, footer, alertOverride, isStandalone }) => {
    const labels = useLabels();
    const { getError, removeError } = useErrorHandlerContext();
    const error = getError(id);

    const isValid = useMemo(() => {
        if (isStandalone) {
            return false;
        }
        else if (error?.type !== 'modal' || !error) {
            return true;
        }
    }, [isStandalone, error]);

    const e = useMemo(() => error as ModalType, [error]);

    const modal = useMemo(() => {
        if (isValid) return null;

        return <Modal
            className={getClassNames([className, style.modal])}
            isOpen={!!error}
            headline={headline ?? error?.headline ?? labels.unknownErrorHeadline}
            body={<Alert
                type='error'
                collapseDetails
                headline={e?.notification?.headline ?? labels.unknownErrorDescription}
                children={e?.notification?.description}
                displayDetailsCta={labels.showMoreDetails}
                details={formatDetails(e?.notification?.details)}
                {...alertOverride}
                detailsClassName={style.details}
            />}
            footer={footer}
            onClose={() => {
                e.onClose?.();
                id && removeError(id);
            }}
            closeOnEscape
        />;
    }, [isValid, className, error, headline, labels.unknownErrorHeadline, labels.unknownErrorDescription, labels.showMoreDetails, e, alertOverride, footer, id, removeError]);


    return modal;
};