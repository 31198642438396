import { type PropsWithChildren, useMemo, } from 'react';
import { useAuth } from '../auth/AuthContext';
import BackendEventProvider from './BackendEventsContext';
import BackendEventsService from '../../services/BackendEventsService';

const BackendEventsServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { getAccessToken, user } = useAuth();

    const service = useMemo(() => BackendEventsService.configure(!!user, getAccessToken), [getAccessToken, user]);

    return <BackendEventProvider service={service}>
        {children}
    </BackendEventProvider>;
};

export default BackendEventsServiceProvider;