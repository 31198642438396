import { useCallback, useEffect, useState } from "react";
import useStorage from "../../hooks/useStorage";
import { DocumentUploadStatus } from '../../helpers/statusHelpers';

const PERSISTENT_FILE_SCOPE = "persistent-Ids";

const usePersistentFileState = () => {
    const [documentIds, setDocuments] = useState<string[]>([]);
    const { get, set, remove } = useStorage(PERSISTENT_FILE_SCOPE, "local", "json");

    // Initialize the state with the stored document ids
    useEffect(() => {
        const storedIds = get(PERSISTENT_FILE_SCOPE) || [];
        if (Array.isArray(storedIds) && storedIds.length > 0) {
            setDocuments(storedIds);
        }
    }, [get, set]);

    const [uploadStatus, setUploadStatus] = useState<DocumentUploadStatus>(
        DocumentUploadStatus.Processing
    );

    const setDocumentIds = useCallback(
        (ids: string[]) => {
            const currentIds = get(PERSISTENT_FILE_SCOPE) || [];
            const updatedIds = Array.from(new Set([...currentIds, ...ids]));
            setDocuments(updatedIds);
            set(PERSISTENT_FILE_SCOPE, updatedIds);
        },
        [get, set]
    );

    const getAllDocumentIds = useCallback((): string[] => {
        // return documentIds;
        const storedIds = get(PERSISTENT_FILE_SCOPE) || [];
        return Array.isArray(storedIds) && storedIds.length > 0 ? storedIds : [];
    }, [get]);

    const removeDocumentId = useCallback(
        (id: string) => {
            const currentIds = get(PERSISTENT_FILE_SCOPE) || [];
            const updatedIds = currentIds.filter((storedId: string) => storedId !== id);

            if (updatedIds.length > 0) {
                set(PERSISTENT_FILE_SCOPE, updatedIds);
                setDocuments(updatedIds);
            } else {
                remove(PERSISTENT_FILE_SCOPE);
                setDocuments([]);
            }
        },
        [get, set, remove]
    );

    const getUploadStatus = useCallback(() => uploadStatus, [uploadStatus]);
    const changeUploadStatus = (status: DocumentUploadStatus) => setUploadStatus(status);

    return {
        documentIds,
        setDocumentIds,
        getAllDocumentIds,
        removeDocumentId,
        getUploadStatus,
        changeUploadStatus,
    };
};

export default usePersistentFileState;