import labels from '../data/labels';
import EnvConfigHelper from '../helpers/envConfigHelper';
import EventHandler from './EventHandler';
import ServiceBackendEventsHelper from './helpers/ServiceBackendEventsHelper';
import { limitSubjectLength } from '../helpers/chatHelpers';
import { HTTPDataService, HttpError } from './HTTPDataService';
import { ChatMessageTracesResponse, ChatMessageStream, ChatMetadata, GetChatResponse, SessionResponse, DocumentInfoModel, DocumentUpdateRequest, DocumentsResponse, DeleteDocumentsResponse } from '../models/ChatTypes';
import { ChatMessageFeedback, ProductivityGainResponse, PromptPayload, UpdateChatMetadataResponse, Vote } from '../models/types';
import { IChatService } from './IChatService';
import { IBackendEventsService } from './IBackendEventsService';
import { ComponentsData, DataRequest, TableRowsResponse } from '../models/SidePanelTypes';
import { DocumentEvent, SessionEvent } from '../models/BackendEvents';

const getResponseContent = async (response: Response) => {
    const text = await response.text();
    const result = { text, data: undefined as any };
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('application/json'))
        try {
            result.data = JSON.parse(text);
        }
        catch (e) {
            console.error('Unable to parse json response', e);
        }
    return result;
};

class ChatService extends HTTPDataService implements IChatService {
    baseURL = EnvConfigHelper.get('api-base-url', '');
    backendEventsHelper: ServiceBackendEventsHelper;
    constructor(public getAccessToken: () => Promise<string | undefined>, backendEvents: IBackendEventsService) {
        super();
        this.backendEventsHelper = new ServiceBackendEventsHelper(this.events, backendEvents);
    }

    getChat = async (sessionId: string) => this.get<GetChatResponse>(`api/me/session/${sessionId}`, { 'include-events': 'true' });

    getAllChats = async () => {
        const result = await this.get<SessionResponse[]>('api/me/session/');
        return result;
    };

    createChat = async (initialMessage: string, llm: string, temperature: number, persona?: string, documentIds?: string[]) => {
        const trimmedMessage = limitSubjectLength(initialMessage);
        const result = await this.post<SessionResponse>('api/me/session/', {}, {
            llm,
            temperature,
            subject: trimmedMessage,
            persona,
            documents: documentIds
        });
        return result;
    };

    updateChatMetadata = async (sessionId: string, metadata: Partial<ChatMetadata>) => {
        const result = await this.patch<UpdateChatMetadataResponse>(`api/me/session/${sessionId}`, {}, metadata);
        return result;
    };

    uploadDocument = async (file: File, sessionId?: string) => {
        const formData = new FormData();
        formData.append("file", file);
        let query;
        if (sessionId && sessionId.trim() !== "") {
            query = { session_id: sessionId };
        }

        const result = await this.post<DocumentInfoModel>('api/me/document', query, formData);
        return result;
    };

    copyDocuments = async (sessionId: string): Promise<DocumentsResponse> => {
        const requestBody = {
            session_id: sessionId,
        };

        try {
            const response: DocumentsResponse = await this.post<DocumentsResponse>('api/me/document/copy', undefined, requestBody);
            return response;
        } catch (error) {
            console.error(labels.copyErrorHeadline, error);
            return { documents: {}, errors: { general: labels.copyErrorHeadline } };
        }
    };

    fetchDocuments = async (fileIds: string[], sessionId?: string): Promise<DocumentsResponse> => {
        if (fileIds.length === 0 && !sessionId) {
            console.log(labels.noLocalDocumentsMessage);
            return { documents: {} };
        }

        try {
            const response: DocumentsResponse = await this.getDocuments(fileIds, sessionId);
            return response;
        } catch (error) {
            console.error(labels.fetchErrorMessage, error);
            return { documents: {}, errors: { general: labels.fetchErrorMessage } };
        }
    };

    downloadDocuments = async (fileIds: string[]): Promise<boolean> => {
        let allSuccess = true;
        for (const fileId of fileIds) {
            const success = await this.downloadDocument(`api/me/document/${fileId}/artifacts/raw`);

            if (!success) {
                console.error(`${labels.downloadErrorUUID} ${fileId}`);
                allSuccess = false;
            }
        }
        return allSuccess;
    };

    getDocuments = async (fileIds: string[], sessionId?: string): Promise<DocumentsResponse> => {
        let queryParams: string;

        // Check if sessionId is present; if so, only include it
        if (sessionId) {
            queryParams = `session_id=${encodeURIComponent(sessionId)}`;
        } else if (fileIds.length > 0) {
            // If sessionId is not present but fileIds are, include document_ids
            queryParams = fileIds
                .map((fileId) => `document_ids=${encodeURIComponent(fileId)}`)
                .join('&');
        } else {
            // If both are absent, return early
            return { documents: {} };
        }

        const additionalParams = [
            'include-state=true',
            'include-index-state=false',
            'include-events=false'
        ].join('&');

        const fullQuery = `${queryParams}&${additionalParams}`;
        const result = await this.get<DocumentsResponse>(`api/me/document?${fullQuery}`);

        return result;
    };

    updateDocumentMetadata = async (
        fileId: string,
        metadata: DocumentUpdateRequest
    ) => {
        try {
            const uri = `api/me/document/${fileId}`;

            const body = {
                display: {
                    name: metadata.display.name,
                    description: metadata.display.description || null
                }
            };

            const result = await this.patch<DocumentInfoModel>(uri, undefined, body);
            return result;
        } catch (error: any) {
            throw new Error(`${labels.updateMetadataErrorMessage} ${error.message || error}`);
        }
    };

    deleteDocuments = async (fileIds: string[]): Promise<DeleteDocumentsResponse> => {
        const uri = `api/me/document`;

        const body = { document_ids: fileIds };

        const result = await this.delete<DeleteDocumentsResponse>(uri, undefined, body);

        return result;
    };

    deleteChat = (sessionId: string) => this.delete<void>(`api/me/session/${sessionId}`);
    deleteAllChats = () => this.delete<void>(`api/me/privacy/clear-all`);

    submitProductivityGain = async (sessionId: string, productivityGain: number) => {
        const uri = `api/me/session/${sessionId}/productivity-gain`;
        const query = { session_id: sessionId };
        const payload = { productivity_gain: productivityGain };
        return this.patch<ProductivityGainResponse>(uri, query, payload);
    };

    prompt = (message: string, sessionId: string) => new Promise<ChatMessageStream>(async (resolve, reject) => {
        const payload: PromptPayload = { message: message };

        const uri = this.toAbsoluteApiUrl(`api/me/chat/${sessionId}`);
        const aborter = new AbortController();
        const token = this.getAccessToken();

        try { await token; }
        catch (e) { return reject(e); }

        const promise = fetch(uri, {
            method: 'POST',
            signal: aborter.signal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await token}`
            },
            body: JSON.stringify(payload),
        });

        let result: ChatMessageStream | undefined;
        let error: HttpError | undefined;
        try {
            const response = await promise;

            if (!response.ok) error = {
                status: response.status,
                statusText: response.statusText,
                ...await getResponseContent(response)
            };
            else {
                const headers = response.headers;
                const aiId = headers.get('x-ai-message-id');
                const humanId = headers.get('x-human-message-id');

                if (!aiId || !humanId) error = { status: 400, statusText: 'Message id headers missing' };
                else {
                    result = {
                        response,
                        aiId,
                        humanId,
                        abort: () => aborter.abort(),
                    };
                }
            }
        }
        catch (e) {
            // https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
            if (e instanceof DOMException) error = { status: 0, statusText: 'abort' };
            else error = { status: 0, statusText: 'network_error' };
        }

        if (result) resolve(result);
        else reject(error ?? { status: -1, statusText: '' });
    });

    private _messageVote = async (sessionId: string, message_id: string, vote: Vote) => {
        const uri = `api/me/feedback/${sessionId}/vote`;
        const payload = { vote, message_id };
        const result = await this.patch<void>(uri, undefined, payload);
        return result;
    };

    messageLike = async (sessionId: string, messageId: string) => {
        const result = await this._messageVote(sessionId, messageId, 1);
        return result;
    };
    messageDislike = async (sessionId: string, messageId: string) => {
        const result = await this._messageVote(sessionId, messageId, -1);
        return result;
    };
    messageResetVote = async (sessionId: string, messageId: string) => {
        const result = await this._messageVote(sessionId, messageId, 0);
        return result;
    };

    messageComment = async (sessionId: string, message_id: string, comment: string) => {
        const uri = `api/me/feedback/${sessionId}/comment`;
        const payload = { comment, message_id };
        const result = await this.patch<void>(uri, undefined, payload);
        return result;
    };

    messageInsights = async (traceId: string) => {
        const uri = `api/me/trace/${traceId}`;
        const result = await this.get<ChatMessageTracesResponse>(uri);
        return result;
    };

    tableData = async (sessionId: string, source: string, page?: number, params?: Record<string, string[]>): Promise<TableRowsResponse> => {
        const uri = `api/me/session/${sessionId}/side-panel-data`;
        const query: DataRequest = { session_id: sessionId, data_key: source };
        const result = await this.get<TableRowsResponse>(uri, query);
        return result ?? { rows: [], count: 0 };
    };

    dataSidePanel = async (sessionId: string, objectId: string) => {
        const uri = `api/me/session/${sessionId}/side-panel-data`;
        const query: DataRequest = { session_id: sessionId, data_key: decodeURIComponent(objectId) };

        const response = await this.get<{ data: ComponentsData; }>(uri, query);
        return response.data;
    };

    private _getMessagesFeedback = async (sessionId: string, message_id?: string) => {
        const uri = `api/me/feedback/${sessionId}`;
        const params: { message_id?: string; } = {};
        if (message_id) params.message_id = message_id;
        const result = await this.get<ChatMessageFeedback[]>(uri, params);
        return result;
    };

    getChatMessagesFeedback = async (sessionId: string) => {
        const result = await this._getMessagesFeedback(sessionId);
        return result;
    };
    getMessageFeedback = async (sessionId: string, messageId: string) => {
        const [result] = await this._getMessagesFeedback(sessionId, messageId);
        return result;
    };

    events = {
        session: new EventHandler<SessionEvent>(),
        document: new EventHandler<DocumentEvent>()
    };

    bindEvents = () => {
        this.backendEventsHelper.bind();
    };
    unbindEvents = () => {
        this.backendEventsHelper.unbind();
    };
}

export default ChatService;