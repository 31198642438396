import React, { FC } from 'react';
import Checkbox from '../checkbox/Checkbox';
import styles from './ListItem.module.scss';

export type ListItemProps = {
    id: string;

    header: React.ReactNode;
    footer: React.ReactNode;
    children?: React.ReactNode;

    itemsSelected?: string[];
    headerClassName?: string;
    childrenClassName?: string;
    footerClassName?: string;

    onItemSelect?: (id: string) => void;
};

const ListItem: FC<ListItemProps> = ({ id, header, footer, children, itemsSelected, headerClassName, childrenClassName, footerClassName, onItemSelect }) => {
    return (
        <div className={styles['list-item']}>
            {onItemSelect && (
                <Checkbox
                    isChecked={!!itemsSelected?.includes(id)}
                    onChange={() => onItemSelect(id)}
                />
            )}

            {(header || children || footer) && <div>
                {header && <div className={headerClassName}>{header}</div>}
                {children && <div className={childrenClassName}>{children}</div>}
                {footer && <div className={footerClassName}>{footer}</div>}
            </div>}
        </div>
    );
};

export default ListItem;